.castigatori {

}

.semiCercTop {
  display: inline-block;
  width: 100%;
  border-radius: 100%;
  background-color: #172490;
  font-size: 200%;
  top: -50%;
  position: relative;
}

.semiCercBottom {
  display: inline-block;
  width: 100%;
  border-radius: 100%;
  background-color: #172490;
  font-size: 200%;
  bottom: -50%;
  position: relative;
}

.containerWrapperStyles {
  overflow: hidden;
  position: relative;
  //background-image: url("../images/produse/cremosso_afine_125g");
}

