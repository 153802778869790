
.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-wrapper {
  display: flex;
  width: 90%;
  position: relative;
  justify-content: center;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 90%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.carousel-content.show-5 > * {
  width: calc(100% / 5);
}

.left-arrow, .right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
}

.left-arrow {
  left: 0;//24px;
  background: transparent no-repeat center center / contain;
  color: transparent;
  border: 0;
  cursor: pointer;
  background-image: url("../images/arrow_left.png");
}

.right-arrow {
  right: 0;//24px;
  background: transparent no-repeat center center / contain;
  color: transparent;
  border: 0;
  cursor: pointer;
  background-image: url("../images/arrow_right.png");
}

@media (hover: none) and (pointer: coarse) {
  .left-arrow, .right-arrow {
    //display: none;
  }
}
