$products-count: 14;
$photoshop-design-primary-color: #172490;
.sd-section__products {
  color: $photoshop-design-primary-color;
  text-align: center;
  padding: 0 2.6% 0;

  @include mobile {
    padding: 5% 4%;
  }

  .sd-section__title {
    font-size: px-to-vw(60);
    margin-bottom: 5%;
    line-height: 1.2;

    @include mobile {
      font-size: px-to-vw_mob(80)
    }
  }

  .slick {

    &-slider {
      margin-bottom: 5%;
    }

    &-slide {
      text-align: center;
      @include mobile {
        -webkit-backface-visibility: hidden;
      }

      .sd-product {
        text-align: center;
        font-size: px-to-vw(16);
        color: $photoshop-design-primary-color;
        outline: none;
        margin-top: 12%;
        transition: opacity 0.2s linear;

        @include pc {
          //opacity: 0;
        }

        @include mobile {
          font-size: px-to-vw(16, 320);
          margin-top: 0;
          opacity: 1;
        }

        .product {
          padding-top: 70%;

          $ids:'cremosso_mousse_cherry_125g' 'cremosso_mousse_strawberry_125g' 'cremosso_afine_125g' 'cremosso_capsuni_125g' 'cremosso_natur_125g' 'cremosso_piersici_125g' 'cremosso_stracciatella_125g' 'cremosso_visine_125g' 'cremosso_natur_400g' 'cremosso_pere_400g' 'cremosso_piersici_400g' 'cremosso_stracciatella_400g' 'cremosso_visine_400g' 'cremosso_natur_multipack_8_125g' 'cremosso_capsuni_si_piersici_multipack_8_125g' 'cremosso_stracciatella_si_visine_multipack_8_125g' 'cremosso_visine_si_afine_multipack_8_125g';

          @each $i in $ids {

            &-#{$i} {
              background: transparent url("../../src/images/produse/#{$i}.png") no-repeat center / auto 100%;

              @include mobile {
                background-size: auto 90%;
              }

              //@if $i >= 12 {
              //  background-size: 70%;
              //  background-position-y: 20%;
              //
              //  @include mobile {
              //    background-size: 75%;
              //  }
              //}
            }

          }
          //@for $i from 1 through $products-count {
          //  &-#{$i} {
          //    background: transparent url("/images/produs-#{$i}.png") no-repeat center / auto 100%;
          //
          //    @include mobile {
          //      background-size: auto 90%;
          //    }
          //
          //    @if $i >= 12 {
          //      background-size: 70%;
          //      background-position-y: 20%;
          //
          //      @include mobile {
          //        background-size: 75%;
          //      }
          //    }
          //  }
          //}
        }

        .name {
          color: inherit;
          margin-top: -5%;
          white-space: pre-wrap;
        }
      }

      &.slick-center {

        .sd-product {
          margin-top: 0;

          .product {
            padding-top: 100%;
            background-size: contain;
          }

          .name {
            margin-top: -10%;
          }
        }
      }

      &.slick-active {

        .sd-product {
          @include pc {
            //opacity: 1;
          }
        }
      }
    }

    &-arrow {

      &.slick-next,
      &.slick-prev {
        z-index: 999;
        outline: none;
        width: 4vw;
        height: 24vw;
        //background-color: #fff;

        &:focus, &:hover {
          outline: none;
          //background-color: transparent !important;
        }

        &::before {
          content: "";
          display: block;
          opacity: 1;
          width: 100%;
          height: 100%;
        }

        @include mobile {
          width: 15vw;
          height: 24vw;
          margin-top: -3rem;
        }

      }

      &.slick-prev {
        left: -50px;
        @include mobile {
          left: 0;
        }

        &::before {
          background: transparent url("../../src/images/arrow_left.png") no-repeat center / contain;
        }
      }

      &.slick-next {
        right: -50px;
        @include mobile {
          right: 0;
        }

        &::before {
          background: transparent url("../../src/images/arrow_right.png") no-repeat center / contain;
        }
      }
    }
  }
}
