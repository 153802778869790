@font-face {
  font-family: "ARIMAMADURAI-BLACK";
  src: url('../../src/fonts/ARIMAMADURAI-BLACK.TTF') format('truetype'),
  url('../../src/fonts/ARIMAMADURAI-BLACK.eot?#iefix') format('eot'),
  url('../../src/fonts/ARIMAMADURAI-BLACK.otf') format('opentype'),
  url('../../src/fonts/ARIMAMADURAI-BLACK.svg') format('svg'),
  url('../../src/fonts/ARIMAMADURAI-BLACK.woff') format('woff'),
  url('../../src/fonts/ARIMAMADURAI-BLACK.woff2') format('woff2');
}

@font-face {
  font-family: "ARIMAMADURAI-BOLD";
  src: url('../../src/fonts/ARIMAMADURAI-BOLD.ttf') format('truetype'),
  url('../../src/fonts/ARIMAMADURAI-BOLD.eot?#iefix') format('eot'),
  url('../../src/fonts/ARIMAMADURAI-BOLD.OTF') format('opentype'),
  url('../../src/fonts/ARIMAMADURAI-BOLD.svg') format('svg'),
  url('../../src/fonts/ARIMAMADURAI-BOLD.woff') format('woff'),
  url('../../src/fonts/ARIMAMADURAI-BOLD.woff2') format('woff2');
}

@font-face {
  font-family: "ARIMAMADURAI-EXTRABOLD";
  src: url('../../src/fonts/ARIMAMADURAI-EXTRABOLD.ttf') format('truetype'),
  url('../../src/fonts/ARIMAMADURAI-EXTRABOLD.eot?#iefix') format('eot'),
  url('../../src/fonts/ARIMAMADURAI-EXTRABOLD.OTF') format('opentype'),
  url('../../src/fonts/ARIMAMADURAI-EXTRABOLD.svg') format('svg'),
  url('../../src/fonts/ARIMAMADURAI-EXTRABOLD.woff') format('woff'),
  url('../../src/fonts/ARIMAMADURAI-EXTRABOLD.woff2') format('woff2');
}

@font-face {
  font-family: "ARIMAMADURAI-MEDIUM";
  src: url('../../src/fonts/ARIMAMADURAI-MEDIUM.ttf') format('truetype'),
  url('../../src/fonts/ARIMAMADURAI-MEDIUM.eot?#iefix') format('eot'),
  url('../../src/fonts/ARIMAMADURAI-MEDIUM.OTF') format('opentype'),
  url('../../src/fonts/ARIMAMADURAI-MEDIUM.svg') format('svg'),
  url('../../src/fonts/ARIMAMADURAI-MEDIUM.woff') format('woff'),
  url('../../src/fonts/ARIMAMADURAI-MEDIUM.woff2') format('woff2');
}

@font-face {
  font-family: 'BerkshireSwash-Regular';
  font-weight: 400;
  font-stretch: normal;
  unicode-range: U+0020-FB02;
  src: url(../../src/fonts/BerkshireSwash-Regular.otf) format('opentype'),
  url(../../src/fonts/BerkshireSwash-Regular.eot?#iefix) format('eot'),
  url(../../src/fonts/BerkshireSwash-Regular.svg) format('svg'),
  url(../../src/fonts/BerkshireSwash-Regular.ttf) format('truetype'),
  url(../../src/fonts/BerkshireSwash-Regular.woff2) format('woff2'),
  url(../../src/fonts/BerkshireSwash-Regular.woff) format('woff');
}

@font-face {
  font-family: 'CentraleSansBold';
  font-weight: 800;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
  src: url(../../src/fonts/CentraleSansBold.otf) format('opentype'),
  url(../../src/fonts/CentraleSansBold.eot?#iefix) format('eot'),
  url(../../src/fonts/CentraleSansBold.svg) format('svg'),
  url(../../src/fonts/CentraleSansBold.ttf) format('truetype'),
  url(../../src/fonts/CentraleSansBold.woff2) format('woff2'),
  url(../../src/fonts/CentraleSansBold.woff) format('woff');
}

@font-face {
  font-family: 'CentraleSansXBold';
  font-weight: 1000;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
  src: url(../../src/fonts/CentraleSansXBold.otf) format('opentype'),
  url(../../src/fonts/CentraleSansXBold.eot?#iefix) format('eot'),
  url(../../src/fonts/CentraleSansXBold.svg) format('svg'),
  url(../../src/fonts/CentraleSansXBold.ttf) format('truetype'),
  url(../../src/fonts/CentraleSansXBold.woff2) format('woff2'),
  url(../../src/fonts/CentraleSansXBold.woff) format('woff');
}

@font-face {
  font-family: 'Inconsolata_Condensed-Light';
  font-weight: 300;
  font-stretch: condensed;
  unicode-range: U+000D-FFFD;
  src: url(../../src/fonts/Inconsolata_Condensed-Light.otf) format('opentype'),
  url(../../src/fonts/Inconsolata_Condensed-Light.eot?#iefix) format('eot'),
  url(../../src/fonts/Inconsolata_Condensed-Light.svg) format('svg'),
  url(../../src/fonts/Inconsolata_Condensed-Light.ttf) format('truetype'),
  url(../../src/fonts/Inconsolata_Condensed-Light.woff2) format('woff2'),
  url(../../src/fonts/Inconsolata_Condensed-Light.woff) format('woff');
}

//@import url("https://cdn-assets.syscomdigital.ro/fonts/?font-family=ArimaDurai");
$photoshop-design-width: 1920;

$psd-width-desktop: 1920;
$psd-width-mobile: 1440;

@import "./mixins/mixins";
@import "./Carousel.module";
@import "./slick/slick.min";
@import "./slick/slick.theme.min";
@import "./Products";
@import "./mobile_menu";


.slick-slide.slick-active {

  opacity: 1;
  transition: opacity 0.2s ease-in-out;

}

.slick-slide:not(.slick-active) {

  opacity: 0;
  transition: opacity 0.2s ease-in-out;

}

@mixin produse() {

  $ids: 'cremosso_mousse_cherry_125g' 'cremosso_mousse_strawberry_125g' 'cremosso_afine_125g' 'cremosso_capsuni_125g' 'cremosso_natur_125g' 'cremosso_piersici_125g' 'cremosso_stracciatella_125g' 'cremosso_visine_125g' 'cremosso_natur_400g' 'cremosso_pere_400g' 'cremosso_piersici_400g' 'cremosso_stracciatella_400g' 'cremosso_visine_400g' 'cremosso_natur_multipack_8_125g' 'cremosso_capsuni_si_piersici_multipack_8_125g' 'cremosso_stracciatella_si_visine_multipack_8_125g' 'cremosso_visine_si_afine_multipack_8_125g';

  @each $v in $ids {

    .#{$v} {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      padding-top: 100%;
      //right: 6%;
      background: transparent no-repeat center center / contain;
      background-image: url("../images/produse/#{$v}.png");
      //position: absolute;
      //top: 37%;
      //top: 8%;

      //@include responsive {
      //  @include removeAbsolute;
      //
      //  background-position: top center;
      //  width: 100%;
      //  background-size: 70%;
      //  padding-top: 85%;
      //
      //}
    }

  }
}

@include produse();

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background: #021938;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.product-name {
  white-space: pre-line;
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: top;
}

.visual_img {
  width: 100%;
  margin: 0;
}

.mobile_hide {
  display: none
}

.MuiInputBase-input::-webkit-input-placeholder {
  opacity: 1 !important;
}


#modal-description {
  white-space: pre-wrap;
  padding-bottom: 1rem;
}

.error {
  color: red !important;
  border: 2px solid red !important;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#sd-page-body-loader {
  display: none;
}

#footerSection {
  .link-social {
    font-size: 0;
    line-height: 0;
    text-decoration: none;
  }
}

#meniu {
  .menu-cremosso-logo {
    width: 15vw;
    padding: 0.5vw 0 0 1vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 120;
    transition: all ease-in-out .2s;

    img {
      width: 100%;
    }
  }

  &.navigating {
    .menu-cremosso-logo {
      width: auto;
      padding: 0.5rem 0 0 1vw;
      img {
        max-height: 1.0rem !important;
      }
    }
  }

}