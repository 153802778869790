@use 'sass:math';

@function str-split($string, $separator) {
  // empty array/list
  $split-arr: ();
  // first index of separator in string
  $index: str-index($string, $separator);
  // loop through string
  @while $index != null {
    // get the substring from the first character to the separator
    $item: str-slice($string, 1, $index - 1);
    // push item to array
    $split-arr: append($split-arr, $item);
    // remove item and separator from string
    $string: str-slice($string, $index + 1);
    // find new index of separator
    $index: str-index($string, $separator);
  }
  // add the remaining string to list (the last item)
  $split-arr: append($split-arr, $string);

  @return $split-arr;
}


@mixin responsive($device-type : 'mobile', $orientation: '', $dev-content: false) {

  @if $orientation != 'portrait' and $orientation != 'landscape' {

    $orientation: '';

  }

  $append-delimiter: space;

  $convert-size-to-em: false;
  $body-font-size: $photoshop-design-font-size-pixels;

  $orientation-query: if($orientation != '', 'and (orientation: #{$orientation})', '');

  $device-groups: (
    //  min-width | max-width | [, min-resolution, [, alias] ]
          mobile: (
                          (0, 420, 0, 'extrem de mic'),
                          (320, 480, 0, 'Testat pe iPhone SE'), // --- Firefox - dev tools - 320x480
                          (360, 640, 0, 'Galaxy S5'),
                          (412, 732, 0, 'Nexus 6P'), // Chrome - dev tools - Nexus 6P
                          (320, 568, 0, 'iPhone 5 | 5S | SE - 4.0"'), // Chrome - dev tools - iPhone 5
                          (375, 667, 0, 'iPhone 6 | Others - 4.7"'), // Chrome - dev tools - iPhone 6
                          (414, 736, 0, 'iPhone 6 Plus | Others - 5.5"'), // Chrome - dev tools - iPhone 6 Plus
          ),
          tablet: (
                          (415, 737, 0, 'VDF 1100 | Others - 7"'), // --- Testat pe VDF 1100
                          (768, 1024, 0, 'iPad | XAVY L8'), // Chrome - dev tools - iPad --- Testat pe iPad si XAVY L8
                          (1024, 1200, 0, 'iPad Pro | VDF 1100 | Others'), // Chrome - dev tools - iPad Pro
          ),
          mt: (
                          (0, 420, 0, 'extrem de mic'),
                          (320, 480, 0, 'Testat pe iPhone SE'), // --- Firefox - dev tools - 320x480
                          (360, 640, 0, 'Galaxy S5'),
                          (412, 732, 0, 'Nexus 6P'), // Chrome - dev tools - Nexus 6P
                          (320, 568, 0, 'iPhone 5 | 5S | SE - 4.0"'), // Chrome - dev tools - iPhone 5
                          (375, 667, 0, 'iPhone 6 | Others - 4.7"'), // Chrome - dev tools - iPhone 6
                          (414, 736, 0, 'iPhone 6 Plus | Others - 5.5"'), // Chrome - dev tools - iPhone 6 Plus
                          (415, 737, 0, 'VDF 1100 | Others - 7"'), // --- Testat pe VDF 1100
                          (768, 1024, 0, 'iPad | XAVY L8'), // Chrome - dev tools - iPad --- Testat pe iPad si XAVY L8
                          (1024, 1200, 0, 'iPad Pro | VDF 1100 | Others'), // Chrome - dev tools - iPad Pro
          ),
          laptop: (
                          (1280, 1440, 0, 'Laptop'),
          ),
          desktop: (
                          (1440, 1600, 0, 'Desktop'),
          ),
          fullhd: (
                          (1601, 9999, 0, 'Full HD'),
          ),
  );


  $split-values: str-split($device-type, ",");
  @each $device-type in $split-values {

    @if map-has-key($device-groups, $device-type) {

      $device-resolutions: map-get($device-groups, $device-type);

      $min-width: 0;
      $max-width: 0;

      $media-query-combined: ();

      @for $j from 1 through length($device-resolutions) {

        $media-query: ();

        $resolution: nth($device-resolutions, $j);

        $ratio-query: '';
        $alias-query: '';

        $media-query: append($media-query, 'screen', space);

        $min-width: if($convert-size-to-em == true, if(nth($resolution, 1) == 0, 0, nth($resolution, 1)/$body-font-size), nth($resolution, 1));
        $max-width: if($convert-size-to-em == true, if(nth($resolution, 2) == 0, 0, nth($resolution, 2)/$body-font-size), nth($resolution, 2));
        $mq-unit: if($convert-size-to-em == true, 'em', 'px');

        $min-width-or-min-device-width: if($device-type == 'tablet', 'min-device-width', 'min-width');
        $max-width-or-max-device-width: if($device-type == 'tablet', 'max-device-width', 'max-width');

        $min-width-append: if($min-width > 0, '(#{$min-width-or-min-device-width}: #{$min-width}#{$mq-unit})', '');
        $max-width-append: if($max-width > 0, '(#{$max-width-or-max-device-width}: #{$max-width}#{$mq-unit})', '');

        @if $min-width > 0 {
          $media-query: append($media-query, 'and #{$min-width-append}', $append-delimiter);
        }
        @if $max-width > 0 {
          $media-query: append($media-query, 'and #{$max-width-append}', $append-delimiter);
        }

        $media-query: append($media-query, $orientation-query, $append-delimiter);

        @if length($resolution) > 2 {

          @if length(nth($resolution,3)) > 0 and (nth($resolution,3)) > 0 {
            $ratio-query: 'and (min-resolution: #{nth($resolution,3)}dppx)';
            $media-query: append($media-query, $ratio-query, $append-delimiter);
          }

          @if length($resolution) == 4 and str-length(nth($resolution,4)) > 0 {
            $alias-query: '; alias: #{nth($resolution,4)}';
          }

        }

        $media-query-combined: append($media-query-combined, $media-query, comma);

        @if $j == length($device-resolutions) and $dev-content == false {

          @media #{$media-query-combined} {

            @content;

          }

        }

        @if $dev-content == true {

          @media #{$media-query} {

            content: 'target: #{$device-type}; orientation: #{if($orientation == '', 'none', $orientation)}; resolution: @media #{$media-query}#{$alias-query}';
            @content;

          }

        }

      }

    } @else {

      @error "Device type '#{$value}' doesn't exist in @mixin responsive";

    }

  }


}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mobile_or_tablet_portrait {
  @media (max-width: 767px), (max-device-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet {

  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }

}

@mixin tablet_laptop {

  @media (min-width: 768px) and (max-width: 1440px) {
    @content;
  }

}

@mixin large_desktop {
  @media (min-width: 1601px) {
    @content;
  }
}

//min-device-width
@mixin tablet-landscape {

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    @content;
  }

}

@mixin tablet-portrait {

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    @content;
  }

}

//Small devices (tablets, 768px and up)
//Medium devices (big landscape tablets, laptops, and desktops, 992px and up)
@mixin pc {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1281px) and (max-width: 1440px) {
    @content;
  }
}

@mixin animation($arguments) {
  -webkit-animation: $arguments;
  -moz-animation: $arguments;
  -o-animation: $arguments;
  -ms-animation: $arguments;
  animation: $arguments;
}

@mixin transition($arguments) {
  -webkit-transition: $arguments;
  -moz-transition: $arguments;
  -o-transition: $arguments;
  -ms-transition: $arguments;
  transition: $arguments;
}

@mixin placeholder {

  &::placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }

}

@mixin linear-gradient($fallback_image, $direction_old, $direction_new, $first_color, $second_color) {
  background-image: $fallback_image;
  background-image: -webkit-linear-gradient($direction_old, $first_color, $second_color);
  background-image: -moz-linear-gradient($direction_old, $first_color, $second_color);
  background-image: -o-linear-gradient($direction_old, $first_color, $second_color);
  background-image: linear-gradient($direction_new, $first_color, $second_color);
  //ex direction_old: left
  //ex direction_new: to right
}


@function px-to-vw($ps-font-size, $max-rez: $photoshop-design-width) {
  @return ( (strip-unit($ps-font-size) / $max-rez) * 100 ) * 1vw;
}
@function px-to-vw_mob($ps-font-size, $max-rez: $psd-width-mobile) {
  @return ( (strip-unit($ps-font-size) / $max-rez) * 100 ) * 1vw;
}

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d6b662+0,fff7c4+17,ca9b36+41,e8bc46+64,ffd85d+76,b48930+100 */
@mixin gradient-text($gradient: 'linear-gradient(116deg, rgba(214,182,98,1) 0%,rgba(255,247,196,1) 17%,rgba(202,155,54,1) 41%,rgba(232,188,70,1) 64%,rgba(255,216,93,1) 76%,rgba(180,137,48,1) 100%)', $bg : 'dark') {

  @supports (mix-blend-mode: lighten) {
    display: inline-block;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background: unquote($gradient);
      pointer-events: none;
    }

    @if ($bg == 'light') {
      color: #000;
      background: #fff;
      mix-blend-mode: multiply;

      &::before {
        mix-blend-mode: screen;
      }
    } @else {
      color: #fff;
      background: #000;
      mix-blend-mode: lighten;

      &::before {
        mix-blend-mode: multiply;
      }
    }
  }
}


@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {

  // Firefox
  & {
    scrollbar-width: thin;
    scrollbar-color: $photoshop-design-primary-color transparent; /* scroll thumb and track */
  }

  // Chrome, Edge, Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 5px;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background: transparent; //$background-color
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin ps-letter-spacing($ps-tracking: 0) {
  letter-spacing: ($ps-tracking / 1000) * 1em;
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function calc-rem($size) {
  $rem-size: ( strip-unit($size) / $photoshop-design-font-size-pixels ) * 1rem;

  @return $rem-size;
}

@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $min-value: calc-rem($min-value);
  $max-value: calc-rem($max-value);
  $min-screen: calc-rem($min-screen);
  $max-screen: calc-rem($max-screen);

  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;

  $sign: "+";
  @if ($b < 0) {
    $sign: "-";
    $b: abs($b);
  }
  //@return calc(#{$a*100}vw #{$sign} #{$b});
}


@mixin interpolate($properties, $min-value, $max-value, $min-screen: 600px, $max-screen:1920px) {
  @each $property in $properties {
    #{$property}: calc-rem($min-value);
  }

  @media (min-width: $min-screen) {
    @each $property in $properties {
      #{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
    }
  }

  @media (min-width: $max-screen) {
    @each $property in $properties {
      #{$property}: calc-rem($max-value);
    }
  }
}


@function px-to-vw-std($ps-font-size,$multiply:1) {
  @return (  $multiply * math.div( $ps-font-size, $psd-width-desktop) * 100 ) * 1vw;
}

@function px-to-vw-mob($ps-font-size,$multiply:1) {
  @return (  $multiply * math.div( $ps-font-size, $psd-width-mobile) * 100 ) * 1vw;
}


@mixin font-size-vw-std($ps-font-size,$multiply:1) {
  font-size: px-to-vw-std($ps-font-size, $multiply);
}

@mixin font-size-vw-mobile($ps-font-size,$multiply:1) {
  font-size: px-to-vw-mobile($ps-font-size, $multiply);
}
