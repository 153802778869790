

#menuToggle {
display: none;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height  :100%

}
#menuArea{
  svg{
    width : 2em;
    height: 2em;
  }
}

.open {
  background-color: #fff;
  width: 28px;
  height: 4px;
  display: block;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  top: 8px;
}

.open:before {
  content: "";
  background-color: #fff;
  width: 28px;
  height: 4px;
  display: block;
  border-radius: 2px;
  position: relative;
  top: -8px;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.open:after {
  content: "";
  background-color: #fff;
  width: 28px;
  height: 4px;
  display: block;
  border-radius: 2px;
  position: relative;
  top: 4px;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.menuOpen {
  width: 28px;
  height: 28px;
  display: block;
  //padding: 15px;
  cursor: pointer;
  float: right;
  margin-top: -.3rem;
}

.menuOpen:hover .open:before {
  top: -9px;
}

.menuOpen:hover .open:after {
  top: 5px;
}

.menu {
  position: fixed;
  width: 100vw;
  //height: 50vh;
  top: 0;
  left: 0;

  //border-bottom: solid 5px #ffd85d;
  //border-bottom-left-radius: 25%;
  //border-bottom-right-radius: 25%;


}

.menu label {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  background-size: 100%;
  cursor: pointer;
  z-index: 1;
}

.menu .menuContent {
  position: relative;
  background: #021938;
  //top: 60%;
  font-size: 1rem;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 120px;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.menu ul li{
  padding-bottom: 1.5rem;
  &:hover{
    color: #FFD85D;
  }
}
.menuEffects {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;

}


.menuEffects ul {
  transform: translateY(0%);
  transition: all 0.5s;
}

#menuToggle[checked] ~ .menuEffects {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;
}
#menuToggle[checked] ~ .menuHidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
}


#menuToggle[checked] ~ .menuEffects ul {
  opacity: 1;
}

//#menuToggle:checked ~ .menuOpen .open {
//  background-color: transparent;
//}

//#menuToggle:checked ~ .menuOpen .open:before {
//  content: "";
//  //background-color: white;
//  transform: rotate(45deg);
//  position: absolute;
//  top: 0;
//  right: 0;
//  z-index: 1;
//}
//
//#menuToggle:checked ~ .menuOpen .open:after {
//  content: "";
//  //background-color: white;
//  transform: rotate(-45deg);
//  position: relative;
//  top: 0;
//  right: 0;
//  z-index: 1;
//}

#menuToggle:not(:checked) ~ .menuEffects ul {
  transform: translateY(-30%);
}

.logo_menu_img{
  width: 30%;
  float: left;
  margin-top: -.3rem;
  padding-bottom: .6rem;
}

.active{
  color : #FFD85D;
}

.activeLink{
  color : #FFD85D;
  border-bottom: 2px solid #FFD85D;
}
